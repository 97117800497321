.cls-component {
    &-extra-content-flight-data {
        &-input-frame {
            border: 1px solid #374151;
            padding: 0.6rem;
        }

        &-field {
            margin-top: 0.8rem;
        }

        &-table {
            .p-datatable .p-datatable-thead>tr>th {
                text-align: right;
                padding: 0.5rem 0.5rem;
                color: #fff;
                background: #0b437a;
            }

            .p-datatable .p-datatable-tbody>tr>td {
                padding: 0.5rem 0;
            }
        }

        &-row {
            background-color: #dcdcdc;
            padding: 0.3rem;

            &-title {
                font-weight: bold;
                padding: 0.2rem;
                margin-bottom: 0.3rem;
                font-size: 1.2rem;
            }

            &-others {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }

            &-text-content {
                flex-grow: 1;
                background-color: #fff;
                font-size: 0.8rem;
                margin: 0 0.3rem 0 0.6rem;
                padding: 0.2rem;
                overflow-y: scroll;
                height: 7rem;
            }

            &-image {
                margin-left: 0.4rem;
            }

            &-new-page{
                text-align: right;
                font-weight: bold;
                margin-top: 0.5rem
            }

            &-buttons {
                text-align: right;
                margin-top: 0.3rem;
                margin-bottom: 0.2rem;
                margin-right: 0.3rem;

                .p-component {
                    font-size: 0.7rem;
                    margin-left: 0.3rem;
                }

                .p-button.p-button-icon-only.p-button-rounded {
                    height: 1.5rem;
                }

                .p-button.p-button-icon-only {
                    width: 1.5rem;
                }
            }
        }

        /*                
        &-row {
            
          

            

            

           

           
            

            &-date {
                font-size: 0.8rem;
                font-weight: bold;
                color: #000;

                padding: 0.3rem;

                &-ok {
                    background-color: #7cfc00;
                    border: 1px solid #648c11;
                }

                &-not-ok {
                    background-color: #ff9999;
                    border: 1px solid #d73b3e;
                }
            }

            

            
        }*/
    }
}