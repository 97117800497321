.cls-component {
    &-toolbar-control-dialog {
        &-pattern{
            p-button,
            p-splitbutton {
                margin-right: 0.3rem;
            }

            .p-toolbar {
                //font-family: 'poetsen one';
                font-size: 0.8rem;
                background: #f8f9fa;
                border: 1px solid #dee2e6;
                padding: 0.5rem 0;
                border-radius: 3px;
                gap: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }
}