.cls-component {
    &-calendar-request {
        .p-datepicker table td {
            padding: 0;
        }

        .p-datepicker table td>span {
            font-size: 0.9rem;
            width: 1.8rem;
            height: 1.8rem;
        }

        .p-datepicker .p-datepicker-header {
            padding: 0;
            color: #000;
            background: #e6e3e3;
            font-weight: bold;
            margin: 0;
            text-transform: uppercase;
        }

        .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
        .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
            color: #000;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            font-weight: bold;
            padding: 0.5rem;
            text-transform: uppercase;
        }

        p-calendar.p-calendar-clearable .p-calendar-clear-icon {
            color: #6c757d;
            right: 0.5rem;
            margin-top: 0.05rem;
            margin-right: 0.8rem;
        }
    }
}