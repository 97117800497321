.cls-component {
    &-dialog-pattern {
        .p-dialog .p-dialog-header {
            padding: 0.5rem;
        }

        .p-dialog .p-dialog-content {
            padding: 0 0.5rem 2rem 0.5rem;
        }

        .p-dialog .p-dialog-footer {
            padding: 0 0.5rem 0.5rem 0.5rem;
        }

        &-title {
            font-size: 1.1rem;
            font-weight: 600;
            background-color: #FFF;
            margin: 0;
            padding: 0.3rem 0 1rem;
            text-transform: uppercase;
        }
    }
}