.cls-component {
    &-dialog-search-aerodrome {
        .p-dialog-resizable{
            width: 85vw;
            height: 80vh;
            
            @media (max-width: 576px) {
                width: 95vw;
                height: 86vh;
            } 
        }

        .p-dialog-content {
            padding: 1rem 0.5rem 0 0.5rem;            
            background-color: #F5F5F5;
        }

        .p-dialog-footer {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .p-fieldset .p-fieldset-content {
            padding: 0;

        }

        .p-fieldset .p-fieldset-legend {
            background-color: #4169E1;
            color: #FFFFFF;
            font-size: 0.9rem;
            letter-spacing: 0.1rem;
            padding: 0.5rem 0.5rem;
        }

        &-table {
            background-color: #FFFFFF;
            border: 1px solid #dee2e6;
            border-top: none;
            padding: 0.5rem 1rem 1rem 1rem;
            margin-left: 0.2rem;
            margin-right: 0.2rem;

            .p-datatable .p-datatable-thead > tr > th {
                background: #C8C8C8;
                font-size: 0.8rem;
                font-weight: 700;
                padding: 0.6rem 0.3rem;

                /*text-align: left;
                padding: 1rem 1rem;
                border: 1px solid #e9ecef;
                border-width: 0 0 1px 0;                
                color: #495057;
                background: #f8f9fa;
                transition: box-shadow 0.2s;*/
            }

            .p-datatable .p-datatable-tbody {
         
            }

            .p-datatable .p-datatable-tbody > tr > td {                
                font-size: 0.85rem;
                padding: 0.4rem 0.3rem;

                /*text-align: left;
                border: 1px solid #e9ecef;
                border-width: 0 0 1px 0;
                padding: 1rem 1rem;*/
            }

            .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
                background: #F0F0F0;
            }

            
            &-action-button{
                img {
                    height: 1.3rem;
                    width: 1.3rem;                    
                }

                .p-button {
                    padding: 0.2rem;
                 }
            }
        }

        &-map {
            &-frame {
                background-color: #FFFFFF;
                border: 1px solid #dee2e6;
                border-top: none;
                padding: 0.5rem 1rem 1rem 1rem;
                margin-left: 0.2rem;
                margin-right: 0.2rem;
            }
        }
    }
}