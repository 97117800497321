[data-top_header="top-header-01"] {
    .cls {
        &-top-hdr {
            &-frame {
                display: flex;
                align-items: center;
                justify-content: space-between;

                background-color: $top_header-background-color;

                &-item01 {
                    background-color: $top_header-background-color;
                    //width: 5.7rem;
                }

                &-item02 {
                    background-color: $top_header-background-color;

                    //flex-grow: 1;       
                    @media (max-width: 576px) {
                        display: none;
                    }
                }

                &-item03 {
                    background-color: $top_header-background-color;
                    
                    display: flex;
                    //align-items: center;
                    flex-wrap: nowrap;
                }
            }            
        }
    }
}