.cls-component {
    &-version-info {
        &-page {
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;

            &-label {
                color: #FF0038;
                margin-right: 1rem;
            }

            &-link {
                a {
                    color: #FFFFFF;
                }
            }
        }

        &-panel {            
            .p-panel .p-panel-header {
                padding: 0.5rem 1rem;
                background: #374151;
                color: #FFF;
                font-size: 1.3rem;
                font-weight: bold;
            }
 
            &-header{
                i{
                    margin-right: 0.3rem;
                }
            }
        }       
    }
}