.cls-component {
    &-dialog-waiting-process {
        .p-dialog {
            border: 10px solid #374151;
            background-color: #e5e4e2;
        }

        .p-dialog .p-dialog-header {
            display: flex;
            justify-content: flex-end;
            background-color: inherit;
            padding-top: 0;
            padding-bottom: 2rem;
        }

        .p-dialog .p-dialog-content {
            background-color: inherit;
        }

        &-image {
            text-align: center;

            img {
                width: 8rem;
                height: 8rem;
            }
        }

        &-label {
            text-align: center;
            font-size: 1.3rem;
            font-weight: 800;
            margin-top: 2.5rem;
        }
    }
}