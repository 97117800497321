.cls-component {
    &-dialog-modal {        
        .p-dialog {
            border: 6px solid #374151;
        }

        .p-dialog .p-dialog-header {
            padding: 0.5rem 1rem;
            text-transform: uppercase;
        }

        .p-dialog .p-dialog-content {
            padding: 0.5rem 1rem;
        }

        .p-dialog .p-dialog-footer {
            padding: 0.5rem 1rem;
        }

        &-title {
            font-size: 1.2rem;
            margin: 0;
        }
    }
}