.cls-component {
    &-upload-dropzone {
        padding: 0.5rem;
        text-align: center;
        border: dashed 1px #979797;
        position: relative;
        padding: 1rem 0 1.5rem 0;
        margin: 0 auto;

        input {
            opacity: 0;
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        label {
            color: #fff;
            width: 183px;
            height: 44px;
            border-radius: 10px;
            background-color: #4b5563;
            padding: 8px 16px;
        }

        // https://medium.com/@tarekabdelkhalek/how-to-create-a-drag-and-drop-file-uploading-in-angular-78d9eba0b854
        h3 {
            font-size: 20px;
            font-weight: 600;
            color: #38424c;
            margin-top: 0;
            margin-bottom: 0.5rem;
        }

        &-animation {
            animation: shake-animation 0.5s;
        }

        &-box-upload {
            padding: 1rem 0;
            background-color: #f8f9fa;
            margin: -0.5rem 0.5rem 1.5rem;

            &-image {
                img {
                    width: 4rem;
                }
            }

            &-label {
                font-weight: bold;
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
            }

            &-advice {
                font-size: 0.8rem;
                font-weight: 500;
            }            
        }     
    }
}

/* SHAKE ANIMATION */
@keyframes shake-animation {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(1deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}