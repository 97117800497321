[data-top_horizontal_menu="top-horizontal-menu-01"] {
    .cls {
        &-top-mnu {
            &-frame {
                .p-menubar {
                    font-family: 'rubik medium';
                    font-size: 0.9rem;
                    padding: 0;
                    background-color: $top_menu-background-color;
                    color: #495057;
                    border: none;

                    @media (max-width: 576px) {
                        padding: 0.4rem 0;
                    }
                }    
                
                .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
                    @media (min-width: 576px) {
                        padding: 0.7rem 0.6rem;
                    }
                }
            }
        }
    }
}