.cls-component {
    &-countdown {
        color:#fff;
        background-color: #374151;
        margin-bottom: 0.4rem;
        padding: 0.5rem 0 0.2rem;
        
        &-wrap {
            display: table;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding: 0.5rem 1rem;

            a {
                text-decoration: none;
                color: #1a1a1a;
            }

            h1 {
                margin-bottom: 60px;
                text-align: center;
                font: 300 2.25em "Lato";
                text-transform: uppercase;
            }

            h1 strong {
                font-weight: 400;
                color:  #374151;
            }

            h2 {
                margin-bottom: 80px;
                text-align: center;
                font: 300 0.7em "Lato";
                text-transform: uppercase;
            }

            h2 strong {
                font-weight: 400;
            }                      
        }

        &-label {
            display: flex;
            font-size: 0.5rem;
            font-weight: bold;
            letter-spacing: 0.2rem;
            margin-top: -0.5rem;
            margin-bottom: 0.3rem;

            &-days{
                text-align: center;
                width: 20%;
            }

            &-content{
                text-align: center;
                width: 80%;
            }
        }

        &-separator {
            font-size: 2rem;
            font-weight: bold;
            margin-left: -0.5rem;
            margin-right: 0.5rem;
            margin-top: -0.2rem;     
            
            @media (min-width: 992px) { 
                font-size: 1.5rem;
                margin-top: 0;
                margin-right: 0.3rem;
            }
        }

        &-clock {
            display: flex;
            width: 100%;
            margin: 0 auto;

            .bloc-time {
                float: left;
                margin-right: 1rem;
                text-align: center;
            }

            .bloc-time:last-child {
                margin-right: 0;
            }

            .count-title {
                display: block;
                margin-bottom: 15px;
                font: normal 0.94em "Lato";
                color: #1a1a1a;
                text-transform: uppercase;
            }

            .figure {
                position: relative;
                float: left;
                //height: 110px;
                //width: 100px;
                height: 35px;
                width: 30px;
                margin-right: 0.3rem;
                background-color: #fff;
                border-radius: 8px;
                -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
                -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
            }

            .figure:last-child {
                margin-right: 0;
            }

            .figure>span {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                //font: normal 5.94em/107px "Lato";
                font: normal 1.3em/33px "Lato";
                font-weight: 700;
                color: #de4848;
                //color: #000;
            }

            @media (min-width: 992px) { 
                .figure {
                    height: 30px;
                    width: 25px;
                    margin-right: 0.3rem;
                }

                .figure>span {
                    font: normal 1.3em/30px "Lato";
                    font-weight: 700;
                }
            }  

            .figure .top:after,
            .figure .bottom-back:after {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            .figure .top {
                z-index: 3;
                background-color: #dedbdbc8;
                transform-origin: 50% 100%;
                -webkit-transform-origin: 50% 100%;
                -moz-border-radius-topleft: 10px;
                -webkit-border-top-left-radius: 10px;
                border-top-left-radius: 10px;
                -moz-border-radius-topright: 10px;
                -webkit-border-top-right-radius: 10px;
                border-top-right-radius: 10px;
                -moz-transform: perspective(200px);
                -ms-transform: perspective(200px);
                -webkit-transform: perspective(200px);
                transform: perspective(200px);
            }

            .figure .bottom {
                z-index: 1;
            }

            .figure .bottom:before {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background-color: rgba(0, 0, 0, 0.02);
            }

            .figure .bottom-back {
                z-index: 2;
                top: 0;
                height: 50%;
                overflow: hidden;
                background-color: #f7f7f7;
                -moz-border-radius-topleft: 10px;
                -webkit-border-top-left-radius: 10px;
                border-top-left-radius: 10px;
                -moz-border-radius-topright: 10px;
                -webkit-border-top-right-radius: 10px;
                border-top-right-radius: 10px;
            }

            .figure .bottom-back span {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            .figure .top,
            .figure .top-back {
                height: 50%;
                overflow: hidden;
                -moz-backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }

            .figure .top-back {
                z-index: 4;
                bottom: 0;
                background-color: #fff;
                -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;
                -moz-transform: perspective(200px) rotateX(180deg);
                -ms-transform: perspective(200px) rotateX(180deg);
                -webkit-transform: perspective(200px) rotateX(180deg);
                transform: perspective(200px) rotateX(180deg);
                -moz-border-radius-bottomleft: 10px;
                -webkit-border-bottom-left-radius: 10px;
                border-bottom-left-radius: 10px;
                -moz-border-radius-bottomright: 10px;
                -webkit-border-bottom-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .figure .top-back span {
                position: absolute;
                top: -100%;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
}