// Os nomes das variáveis devem ser compostas na seguinte ordem, quando existir as informações: $component-state-property-size

/* Poetsen One */
@font-face {
  font-family: "poetsen one";
  src: url("../../archive/fonts/Poetsen_One/PoetsenOne-Regular.ttf");
  src: local('poetsen one'), url("../../archive/fonts/Poetsen_One/PoetsenOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Aleo */
@font-face {
  font-family: "aleo";
  src: url("../../archive/fonts/Aleo/static/Aleo-Regular.ttf");
  src: local('aleo regular'), url("../../archive/fonts/Aleo/static/Aleo-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "aleo medium";
  src: url("../../archive/fonts/Aleo/static/Aleo-Medium.ttf");
  src: local('aleo medium'), url("../../archive/fonts/Aleo/static/Aleo-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "aleo semibold";
  src: url("../../archive/fonts/Aleo/static/Aleo-SemiBold.ttf");
  src: local('aleo semibold'), url("../../archive/fonts/Aleo/static/Aleo-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "aleo bold";
  src: url("../../archive/fonts/Aleo/static/Aleo-SemiBold.ttf");
  src: local('aleo bold'), url("../../archive/fonts/Aleo/static/Aleo-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Dancing */
@font-face {
  font-family: "dancing";
  src: url("../../archive/fonts/Dancing/static/DancingScript-Regular.ttf");
  src: local('dancing regular'), url("../../archive/fonts/Dancing/static/DancingScript-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "dancing medium";
  src: url("../../archive/fonts/Dancing/static/DancingScript-Medium.ttf");
  src: local('dancing medium'), url("../../archive/fonts/Dancing/static/DancingScript-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "dancing semibold";
  src: url("../../archive/fonts/Dancing/static/DancingScript-SemiBold.ttf");
  src: local('dancing semibold'), url("../../archive/fonts/Dancing/static/DancingScript-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "dancing bold";
  src: url("../../archive/fonts/Dancing/static/DancingScript-SemiBold.ttf");
  src: local('dancing bold'), url("../../archive/fonts/Dancing/static/DancingScript-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Montserrat */
@font-face {
  font-family: "montserrat light";
  src: url("../../archive/fonts/Montserrat/static/Montserrat-Light.ttf");
  src: local('montserrat light'), url("../../archive/fonts/Montserrat/static/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "montserrat";
  src: url("../../archive/fonts/Montserrat/static/Montserrat-Regular.ttf");
  src: local('montserrat regular'), url("../../archive/fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "montserrat medium";
  src: url("../../archive/fonts/Montserrat/static/Montserrat-Medium.ttf");
  src: local('montserrat medium'), url("../../archive/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "montserrat semibold";
  src: url("../../archive/fonts/Montserrat/static/Montserrat-SemiBold.ttf");
  src: local('montserrat semibold'), url("../../archive/fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "montserrat bold";
  src: url("../../archive/fonts/Montserrat/static/Montserrat-Bold.ttf");
  src: local('montserrat bold'), url("../../archive/fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Oswald */
@font-face {
  font-family: "oswald light";
  src: url("../../archive/fonts/Oswald/static/Oswald-Light.ttf");
  src: local('oswald light'), url("../../archive/fonts/Oswald/static/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "oswald";
  src: url("../../archive/fonts/Oswald/static/Oswald-Regular.ttf");
  src: local('oswald regular'), url("../../archive/fonts/Oswald/static/Oswald-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "oswald medium";
  src: url("../../archive/fonts/Oswald/static/Oswald-Medium.ttf");
  src: local('oswald medium'), url("../../archive/fonts/Oswald/static/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "oswald semibold";
  src: url("../../archive/fonts/Oswald/static/Oswald-SemiBold.ttf");
  src: local('oswald semibold'), url("../../archive/fonts/Oswald/static/Oswald-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "oswald bold";
  src: url("../../archive/fonts/Oswald/static/Oswald-Bold.ttf");
  src: local('oswald bold'), url("../../archive/fonts/Oswald/static/Oswald-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Poppins */
@font-face {
  font-family: "poppins light";
  src: url("../../archive/fonts/Poppins/Poppins-Light.ttf");
  src: local('poppins light'), url("../../archive/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "poppins";
  src: url("../../archive/fonts/Poppins/Poppins-Regular.ttf");
  src: local('poppins regular'), url("../../archive//fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "poppins medium";
  src: url("../../archive/fonts/Poppins/Poppins-Medium.ttf");
  src: local('poppins medium'), url("../../archive//fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "poppins semibold";
  src: url("../../archive/fonts/Poppins/Poppins-SemiBold.ttf");
  src: local('poppins semibold'), url("../../archive/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "poppins bold";
  src: url("../../archive/fonts/Poppins/Poppins-Bold.ttf");
  src: local('poppins bold'), url("../../archive/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Roboto */
@font-face {
  font-family: "roboto light";
  src: url("../../archive/fonts/Roboto/Roboto-Light.ttf");
  src: local('roboto light'), url("../../archive/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "roboto";
  src: url("../../archive/fonts/Roboto/Roboto-Regular.ttf");
  src: local('roboto regular'), url("../../archive/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "roboto medium";
  src: url("../../archive/fonts/Roboto/Roboto-Medium.ttf");
  src: local('roboto medium'), url("../../archive/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "roboto bold";
  src: url("../../archive/fonts/Roboto/Roboto-Bold.ttf");
  src: local('roboto bold'), url("../../archive/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Rubik */
@font-face {
  font-family: "rubik light";
  src: url("../../archive/fonts/Rubik/static/Rubik-Light.ttf");
  src: local('rubik light'), url("../../archive/fonts/Rubik/static/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "rubik";
  src: url("../../archive/fonts/Rubik/static/Rubik-Regular.ttf");
  src: local('rubik regular'), url("../../archive/fonts/Rubik/static/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "rubik medium";
  src: url("../../archive/fonts/Rubik/static/Rubik-Medium.ttf");
  src: local('rubik medium'), url("../../archive/fonts/Rubik/static/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "rubik semibold";
  src: url("../../archive/fonts/Rubik/static/Rubik-SemiBold.ttf");
  src: local('rubik semibold'), url("../../archive/fonts/Rubik/static/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "rubik bold";
  src: url("../../archive/fonts/Rubik/static/Rubik-Bold.ttf");
  src: local('rubik bold'), url("../../archive/fonts/Rubik/static/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
}