.cls-component {
    &-show-content-pdf {
        &-sigmet {
            &-legend {
                .cls-component-table-form-list .p-datatable .p-datatable-tbody>tr>td {
                    font-weight: bold;
                }

                &-color {
                    width: 100%;
                    height: 1rem;
                }
            }

            &-data {
                margin: 1rem 0.5rem;

                &-title {
                    display: flex;
                    flex-wrap: nowrap;
                    font-weight: bold;

                    div {
                        padding: 0.3rem;
                    }
                }

                &-date {
                    font-weight: 500;
                }
            }
        }

        &-panel {
            .p-panel .p-panel-header {
                border: 1px solid rgb(222, 226, 230);
                padding: 0.9rem;
                background: rgb(248, 249, 250);
                color: rgb(73, 80, 87);
            }

            .p-panel .p-panel-header .p-panel-header-icon {
                width: 1.2rem;
                height: 1.2rem;
                color: rgb(73, 80, 87);
            }
        }

        &-title {
            font-size: 1rem;
            font-weight: bold;
        }

        &-expand-retract {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 0.8rem;
            margin-bottom: 0.5rem;

            .p-button.p-button-icon-only.p-button-rounded {
                height: 1.8rem;
            }

            .p-button.p-button-icon-only {
                width: 1.8rem;
                padding: 0.5rem 0;
            }

            button {
                margin-left: 0.6rem;
            }
        }

        &-date-generation {
            font-size: 0.8rem;
            font-weight: bold;
            color: #000;

            padding: 0.1rem 0.2rem;

            &-ok {
                background-color: #7cfc00;
                border: 1px solid #648c11;
            }

            &-not-ok {
                background-color: #ff9999;
                border: 1px solid #d73b3e;
            }
        }

        &-pseudo-table {
            ul {
                margin: 0 !important;
            }

            &-sun {
                display: flex;
                justify-content: space-around;
                margin-top: -0.5rem;
                margin-bottom: -0.5rem;

                &-sunrise {
                    display: flex;
                    align-items: center;

                    img {
                        height: 2.5rem;
                        width: 2.5rem;
                    }
                }

                &-sunset {
                    display: flex;
                    align-items: center;

                    img {
                        height: 2.5rem;
                        width: 2.5rem;
                    }
                }
            }

            &-img-aerodrome {
                display: block;
                background-image: url('../../../../../assets/imgs/no_image_available.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                width: 95%;
                margin-left: 1rem;
                margin-right: 1rem;
                margin-top: 0.5rem;
                height: 20.8rem;
                text-align: center;

                img {
                    width: 100%;
                    //height: 20.7rem;
                }
            }

            &-grid-col {
                padding: 0;
                margin-top: -0.5rem;
            }



            &-row {
                display: flex;
                align-items: center;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                font-size: 0.8rem;

                padding: 0.5rem;

                background-color: #f5f5f5;

                &-title {
                    margin-right: 0.3rem;
                    width: 22%;
                }

                &-value {
                    font-weight: bold;
                    width: 67%;
                }

                &-box {
                    display: flex;
                    align-items: center;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 0.8rem;

                    padding: 0.5rem;

                    background-color: #f5f5f5;

                    //background-color: #7cfc00;

                    &-title {
                        margin-right: 0.3rem;
                        width: 14%;
                    }

                    &-value {
                        font-weight: bold;
                        width: 85%;

                        &-item {
                            display: flex;
                            align-items: center;
                            background-color: #D8D8D8;
                            width: 100%;
                            margin: 0.3rem 0;
                            padding: 0.3rem;
                        }
                    }
                }
            }
        }
    }
}