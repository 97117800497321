.cls-component {
    &-bottom-utc-datetime {
        &-label {
            font-size: 0.8rem;
            font-weight: 500;
        }

        &-alert {
            .fa-solid {
                color: #FFFF00;
            }            
        }

        &-info {
            .fa-solid {
                color: #AAFF00;
            }
        }

        &-clock {
            display: flex;
            align-items: center;

            font-size: 1rem;
            font-weight: 600;
            width: 10.2rem;            
        }
    }
}