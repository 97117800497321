.cls-component {
    &-compose-input-top-risk{
        &-frame{
            display: flex;
        }
        
        &-choice{
            width: 40%;
        }

        &-data{
            width: 60%;
        }
    }
}