.cls-component {
    &-phone-input {
        &-label {

        }

        &-phone {
            display: flex;

            margin-top: -0.2rem;

            &-ddi {
                width: 40%;
            }

            &-ddd {
                width: 20%;
            }
            
            &-number {
                width: 40%;
            }
        }
    }
}