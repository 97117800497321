.cls {
    &-component-fieldset-filter {
        .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
            padding: 0rem;
            color: #495057;
            border-radius: 3px;
            transition: box-shadow 0.2s;
        }    
        
        .p-fieldset-legend{
            padding: 0.7rem 0.5rem;
        }
    }
}