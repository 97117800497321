.cls-component {
    &-map-legend {
        &-card {
            .p-card .p-card-content {
                padding: 0;
            }

            .p-card .p-card-body {
                padding: 0.5rem;
            }
        }

        &-frame {
            display: flex;
            align-items: center;
            justify-content: space-around;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                &-legend {
                    font-size: 0.8rem;
                    font-weight: 500;
                }

                img {
                    height: 28px;
                    width: 28px;
                }
            }
        }
    }
}