.cls-component {
    &-table-frame{                
        &-01{
            border: 1px solid #ced4da;

            height: 150px;
        }

        &-02{
            border: 1px solid #ced4da;

            height: 227px;
        }        
    }
}