[data-theme="fly-solutions"] {
    :root {}

    /*.p-panel {
        margin: 0.7rem;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

        & .p-panel-header {
            border: 1px solid #dee2e6;
            padding: 0.6rem 1rem;
            background: #292B30;
            color: #FFFFFF;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;

            & .p-panel-title {
                font-weight: 600;
            }

            & .p-panel-header-icon {
                width: 2rem;
                height: 2rem;
                color: #FFFFFF;
                border: 0 none;
                background: transparent;
                border-radius: 50%;
                transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            }

            & .p-panel-header-icon:enabled:hover {
                color: #574949;
                border-color: transparent;
                background: #e9ecef;
            }

            & .p-panel-header-icon:focus-visible {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #a6d5fa;
            }
        }

        &.p-panel-toggleable .p-panel-header {
            padding: 0.1rem 1rem;
        }

        & .p-panel-content {
            padding: 0.5rem 1rem;
            border: 1px solid #dee2e6;
            background: #ffffff;
            color: #495057;
            border-top: 0 none;
        }

        & .p-panel-content:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        & .p-panel-footer {
            padding: 0.5rem 1rem;
            border: 1px solid #dee2e6;
            background: #ffffff;
            color: #495057;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top: 0 none;
        }

        & .p-panel-icons-end {
            order: 2;
            margin-left: auto;
        }

        & .p-panel-icons-start {
            order: 0;
            margin-right: 0.5rem;
        }

        & .p-panel-icons-center {
            order: 2;
            width: 100%;
            text-align: center;
        }
    }

    .p-inputtext {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #495057;
        background: #ffffff;
        padding: 0.5rem 0.5rem;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 3px;

        &:enabled:hover {
            border-color: #2196F3;
        }

        &:enabled:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #a6d5fa;
            border-color: #2196F3;
        }

        &.ng-dirty.ng-invalid {
            border-color: #f44336;
        }

        &.p-inputtext-sm {
            font-size: 0.875rem;
            padding: 0.4375rem 0.4375rem;
        }

        &.p-inputtext-lg {
            font-size: 1.25rem;
            padding: 0.625rem 0.625rem;
        }
    }

    .p-input-icon-left>.p-icon-wrapper.p-icon,
    .p-input-icon-left>i:first-of-type {
        left: 0.5rem;
        color: #6c757d;
    }

    .p-input-icon-left>.p-inputtext {
        padding-left: 2rem;
    }

    .p-input-icon-left.p-float-label>label {
        left: 2rem;
    }

    .p-input-icon-right>.p-icon-wrapper,
    .p-input-icon-right>i:last-of-type {
        right: 0.5rem;
        color: #6c757d;
    }

    .p-input-icon-right>.p-inputtext {
        padding-right: 2rem;
    }

    .p-dropdown {
        background: #ffffff;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 3px;

        &:not(.p-disabled):hover {
            border-color: #2196F3;
        }

        &:not(.p-disabled).p-focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #a6d5fa;
            border-color: #2196F3;
        }

        &.p-dropdown-clearable .p-dropdown-label {
            padding-right: 1.5rem;
        }

        & .p-dropdown-label {
            background: transparent;
            border: 0 none;
        }

        & .p-dropdown-label.p-placeholder {
            color: #6c757d;
        }

        & .p-dropdown-trigger {
            background: transparent;
            color: #6c757d;
            width: 2.357rem;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        & .p-dropdown-clear-icon {
            color: #6c757d;
            right: 2.357rem;
        }

        &.p-invalid.p-component {
            border-color: #f44336;
        }
    }


    // (CANTILEVER) - ACRESCIDO, POIS NÃO TEM NO ORIGINAL
    .p-dropdown-sm .p-dropdown > .p-inputtext{
        font-size: 0.875rem;
        padding: 0.4375rem 0.4375rem;
    }

    // (CANTILEVER) - ACRESCIDO, POIS NÃO TEM NO ORIGINAL
    .p-dropdown-lg .p-dropdown > .p-inputtext {
        font-size: 1.25rem;
        padding: 0.625rem 0.625rem;
    }

    // (CANTILEVER) - ACRESCIDO, POIS NÃO TEM NO ORIGINAL
    .is-danger .p-dropdown{
        color: #ffffff;
        border-color: #c02929;
    }

    .p-dropdown .p-dropdown-label:focus,
    .p-dropdown .p-dropdown-label:enabled:focus {
        outline: 0 none;
        box-shadow: none;
    }

    .p-button.p-button-danger,
    .p-buttonset.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button {
        color: #ffffff;
        background: #d32f2f;
        border: 1px solid #d32f2f;
    }

    .p-button.p-button-danger:not(:disabled):hover,
    .p-buttonset.p-button-danger>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):hover {
        background: #c02929;
        color: #ffffff;
        border-color: #c02929;
    }

    .p-button.p-button-danger:not(:disabled):focus,
    .p-buttonset.p-button-danger>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):focus {
        box-shadow: 0 0 0 0.2rem #edacac;
    }

    .p-button.p-button-danger:not(:disabled):active,
    .p-buttonset.p-button-danger>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):active {
        background: #aa2424;
        color: #ffffff;
        border-color: #aa2424;
    }

    .p-button.p-button-danger.p-button-outlined,
    .p-buttonset.p-button-danger>.p-button.p-button-outlined,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined {
        background-color: transparent;
        color: #d32f2f;
        border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:not(:disabled):hover,
    .p-buttonset.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(211, 47, 47, 0.04);
        color: #d32f2f;
        border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:not(:disabled):active,
    .p-buttonset.p-button-danger>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(211, 47, 47, 0.16);
        color: #d32f2f;
        border: 1px solid;
    }

    .p-button.p-button-danger.p-button-text,
    .p-buttonset.p-button-danger>.p-button.p-button-text,
    .p-splitbutton.p-button-danger>.p-button.p-button-text {
        background-color: transparent;
        color: #d32f2f;
        border-color: transparent;
    }

    .p-button.p-button-danger.p-button-text:not(:disabled):hover,
    .p-buttonset.p-button-danger>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(211, 47, 47, 0.04);
        border-color: transparent;
        color: #d32f2f;
    }

    .p-button.p-button-danger.p-button-text:not(:disabled):active,
    .p-buttonset.p-button-danger>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):active {
        background: rgba(211, 47, 47, 0.16);
        border-color: transparent;
        color: #d32f2f;
    }
    */
}