.cls-component {
    &-buttons-control {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: right;

        background-color: $top_header-background-color;
        height: 3.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 14rem;

        @media (max-width: 576px) {  
            font-size: 0.7rem;          
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            width: 11.5rem;

            .p-button.p-button-icon-only {
                width: 2rem;
            }

            .p-button.p-button-icon-only.p-button-rounded {
                height: 2rem;
            }
        }

        .p-button.p-button-info {
            color: #ffffff;
            background: #6495ED;
            border: 2px solid #6495ED;
        }

        &-search {
            @media (min-width: 576px) {
                display: none;
            } 
        }
    }
}