.cls-component {
    &-button-pattern-default {
        font-family: 'poetsen one';
        font-size: 0.7rem;

        .p-button{
            padding:0.5rem 0.3rem;
        }
        
        
        .p-button-label{
            letter-spacing: 0.1rem !important;
        }

        .p-button-icon{
            font-size: 0.85rem; 
        }

        &-split {
            .p-splitbutton-defaultbutton {
                font-family: 'poetsen one';
                font-size: 0.7rem;      
                padding: 0.53rem 0.5rem;    
            }

            .p-button-label{
                letter-spacing: 0.1rem !important;
            }
    
            .p-splitbutton-defaultbutton > .p-button-icon {
                @media (max-width: 860px) {
                    // TELA DE NOTEPAD
                    margin-right: -0.5rem;
                    font-weight: bold;
                }
            }
    
            .p-splitbutton-menubutton {
                padding: 0;              
            }
        }        
    }
}