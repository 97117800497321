.cls-component {
    &-table-form-list {
        .p-datatable .p-datatable-header {
            background: #f8f9fa;
            color: #000;
            padding: 0.4rem 1rem;
            font-size: 0.9rem;
            font-weight: bold;
        }

        .p-datatable .p-datatable-thead>tr>th {
            padding: 0.6rem 1rem;
            color: #FFF;
            background: #0f4591;
            font-size: 0.8rem;
        }

        .p-datatable .p-datatable-tbody>tr>td {
            padding: 0.7rem 1rem;
            font-size: 0.8rem;
        }

        .p-datatable .p-datatable-tbody {}

        &-caption {
            .p-button {
                padding: 0.4rem 0.4rem;
                font-size: 0.7rem;
                width: 1.8rem
            }
        }

        &-item {
            &-actions {                
                padding: 0 !important;
                text-align: right !important;

                .p-button.p-button-icon-only.p-button-rounded {
                    height: 1.8rem;
                    width: 1.8rem;
                    font-size: 0.85rem;
                }
            }
        }
    }
}