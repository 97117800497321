.cls-component{
    &-flight-request-control{
        &-frame-button{
            padding: 0
        }

        &-button{
            display: grid;
            width: 95%;
        }
    }
}