.cls-component {
    &-aircraft-avatar {
        //padding-left: 0.5rem;
        //padding-right: 0.5rem;        

        &-image {
            &-frame {
                border: 1px solid #ced4da;
                margin-top: -0.2rem;
                margin-bottom: 0.5rem;
                //padding: 0.4rem 0.4rem;
                text-align: center;

                img {
                    margin-top: 0.2rem;
                    width: 60%;
                    max-height: 10rem;
                }

                &-frame-button-delete{
                    width:100%;
                    margin-top: 0.3rem;
                    margin-bottom: 0.5rem;
                }
            }

            &-button-delete {               
                .p-button.p-button-sm {
                    font-size: 0.7rem;
                    letter-spacing: 0.15rem;
                    padding: 0.3rem 0.6rem;
                }

                .p-button.p-button-sm .p-button-icon {
                    font-size: 0.7rem;
                }
            }
        }

        &-color {
            margin-top: 0.3rem;
            margin-bottom: -0.5rem;;
        }
    }
}