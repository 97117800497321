.cls-component {
    &-fixed-footer-page {        
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3rem;;
        background-color: #f5f5f5;
        color: #808080;
        z-index: 2000;

        &-frame{
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
    }
}