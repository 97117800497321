.cls-component {
    &-page-tabview{
        display: block;
        border: 1px solid #dee2e6;

        .p-tabview .p-tabview-panels {
            padding: 0;
        }

        &-content-frame{
            padding: 1rem;
            padding-bottom: 0.5rem;
        }

        &-tabpainel {
            &-legend{
                padding-left: 0.4rem;
            }            
        }
    }
}
