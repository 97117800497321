.cls {
    &-dropdown-svg{
        svg{
            position: relative;
            right: 0;
        }

        .p-dropdown-clear-icon{
            top: 0.9rem;
        }
    }

    &-text-uppercase {
        text-transform: uppercase;
    }

    &-text-lowercase {
        text-transform: lowercase;
    }

    &-label-bold {
        font-weight: bold;
    }

    &-field-wrong {
        color: #FF0000 !important;
        margin-bottom: 0;
    }

    &-no-border {
        border: none !important;
    }

    &-content-inside-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    &-page-box {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    &-page-box-left {
        padding-left: 0.5rem;
    }

    &-page-box-right {
        padding-right: 0.5rem;
    }

    &-highlight-field {
        background-color: #F0F0F0;
    }

    &-hidden {
        display: none;
    }

    &-no-padding {
        padding: 0;
    }
}

.cls {
    &-content-box {
        height: 100%;
        width: 100%;
        max-width: 98%;
        margin: 0 auto;

        @media (min-width: 1200.1px) {
            max-width: 96%;
            margin: 0 auto;
        }
    }
}

// ESTRUTURA BÁSICA DO CONTEÚDO DA PAGINAS
.cls {
    &-remove-margin-grid{
        margin-top:-0.5em !important; 
        margin-left:-0.5em !important;      
        margin-right:-0.5em !important;
    }

    &-page-frame {
        .grid {
            margin-right: 0rem;
            margin-left: 0rem;
            margin-top: 0rem;
        }
        
        &-title {
            background-color: #FFF;
            margin: 0;
            padding: 0.8rem 0 1rem;
            text-transform: uppercase;

            h1 {
                font-size: 1.2rem;
                margin: 0;
            }
        }

        &-content-frame-grid{
            margin-left: -0.5rem
        }
    }
}

// ESTRUTURA BÁSICA DO CONTEÚDO DA PAGINAS
.cls {
    &-form-basic-login {
        display: block;
        width: 50%;
        height: 500px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        margin: auto;

        &-frame {
            border: 1px solid #FFF;
            margin: 0 auto;
            width: 100%;
            height: 100%;
        }

        &-form{            
            padding: 8rem 1rem ;
            height: 250px;
        }

        label {
            color: #FFF;
        } 
    }
}