.cls-ui-primeng {
    &-field {
        &-color-picker {
            padding: 0.2rem;
            border: 1px solid #ced4da;

            .p-colorpicker {
                width: 100%;
            }

            .p-colorpicker-preview {
                width: 100%;
                height: 1.5rem;
            }

            &-elements{
                display: flex;
                width: 100%;

                p-colorpicker{
                    width: 69%;
                    margin-right: 1%;
                }

                input {
                    width: 30%;
                    color: #000;
                }                
            }
        }

        &-switch {
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            width: 100%;

            font-weight: 400;

            .p-inputswitch {
                height: 1.5rem;
            }

            .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
                height: 1.5rem;
            }

            &-item {
                &-left {
                    font-family: "Rubik", sans-serif;
                    font-size: 0.8rem;
                    font-weight: 400;
                    margin-right: 0.15rem;
                    margin-top: -0.1rem;
                }

                &-right {
                    font-family: "Rubik", sans-serif;
                    font-size: 0.8rem;
                    font-weight: 400;
                    margin-left: 0.15rem;
                    margin-top: -0.1rem;
                }
            }
        }

        &-slider {
            display: flex;
            align-items: center;
            padding: 0.5rem 0 1rem 0;

            &-component {
                flex-grow: 1;
            }

            &-frame {
                display: flex;
                align-items: center;
                background-color: #E5E4E2;
                border: 1px solid #899499;
                font-size: 0.9rem;
                font-weight: bold;
                flex-grow: 0;
                margin-left: 1rem;
                padding: 0.2rem 0.2rem;
            }

            &-value {
                //width: 2.3rem;
                padding: 0 0.3rem;
                text-align: center;
            }
        }

        &-chips {
            .p-chips {
                width: 100%;
            }

            ul {
                width: 100%;
            }
        }

        &-checkbox{
            padding: 0.3rem 0;

            &-label{
                /*font-size: 1.1rem !important; // TEM QUE REVER A IMPORTÂNCIA DO "!important"*/
                font-size: 1.1rem; // TEM QUE REVER A IMPORTÂNCIA DO "!important"
                margin-left: 0.3rem;                
            }
        }
    }


    &-toast {
        // ACEITA o '\n' para quebrar a linha na mensagem
        white-space: pre-line !important;
    }
}

/*
.p-inputtext.ng-dirty.ng-invalid > .field {
    margin-bottom: 0 !important;
}
*/