.cls-component {
    &-company-logo {
        &-image-frame {            
            border: 1px solid #ced4da;
            height: 3rem;
            margin-top: 0.4rem;
            padding:.3rem;
        
            img {
                height: 2.3rem;
                width: 100%;
            }
        }
    }
}