.cls-component {
    &-field-route {
        display: flex;
        width: 100%;

        &-text{
            flex-grow: 1;
        }

        &-buttons{
            display: flex;
            flex-direction: column;
            padding-top: 1.2rem;      
            
            &-item{
                padding-bottom: 0.5rem;
            }
        }
    }
}