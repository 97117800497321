.cls-component {
    &-table-page-list {
        .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
            font-size: .9rem;
            font-weight: 500;
        }

        .p-datatable .p-datatable-thead>tr>th {
            color: #FFFFFF;
            background: #3d0c02;
            padding: 0.5rem 0.5rem;
            font-weight: 600;
            font-size: 0.7rem;
        }

        .p-icon {
            width: 0.7rem;
            height: 0.7rem;
        }

        &-table-title {
            margin-left: 0.5rem;
            font-weight: 700;
            font-size: 0.9rem;
        }

        &-filter-title {
            font-weight: 700;
            font-size: 0.8rem;
        }

        &-td-thumb {
            img {
                height: 7rem;
                width: 11rem;
            }
        }

        &-td-action {
            button {
                margin-left: 0.3rem;
            }

            .p-button.p-button-icon-only.p-button-rounded {
                height: 1.8rem;
                font-size: 0.8rem;
            }

            .p-button.p-button-icon-only {
                width: 1.8rem;
                padding: 0.5rem 0;
            }
        }
    }
}