.cls-component {
    &-field-input-with-button {
        display: flex;
        align-content: center;
        align-items: stretch;
        width: 100%;

        &-aslabel {
            .p-inputtext {
                background-color: #E0E0E0;
                font-weight: bold;
            }

            .p-component:disabled {
                opacity: 0.8;
            }
        }

        &-button {
            margin-top: 1.4rem;
            margin-left: 0.2rem;

            .p-button {
                padding: 0.2rem 0;

                span {
                    font-size: 0.8rem;
                }
            }

            .p-button.p-button-icon-only {
                width: 1.8rem;
            }

            &-label {
                margin-top: 0.85rem;
            }
        }
    }
}