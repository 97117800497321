.cls-component {
    &-panel-formgrid {
        display: block;
        margin-bottom: 1rem;

        .p-dropdown {
            position: inherit;
        }

        .p-panel .p-panel-header {
            border: 1px solid rgb(222, 226, 230);
            padding: 0.5rem 1rem;
            background: #374151;
            color: #FFFFFF;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            font-size: 0.8rem;
            letter-spacing: 0.1rem;
        }

        .p-panel .p-panel-content {
            padding: 0.4rem 0.5rem;
            border: 1px solid #dee2e6;
            background: #ffffff;
            color: #495057;
            border-top: 0 none;
        }

        .p-panel .p-panel-header .p-panel-header-icon {
            width: 1rem;
            height: 1rem;
            color: #fff;
        }

        .p-icon {
            width: 0.7rem;
            height: 0.7rem;
        }
    
        &-header-button {
            .p-button {
                padding: 0.1rem 0;

                span {
                    font-size: 0.7rem;
                }
            }

            .p-button.p-button-icon-only {
                width: 1.6rem;
            }
        }

        &-sub-menu {
            display:block;  

            
        }
    }
}