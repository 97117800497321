// NORMALIZE
@import 'normalize.css';

// PRIMENG
@import 'primeflex/primeflex.scss';
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-blue/theme.css"; // DEFAULT THEME - Define Color Scheme
@import 'primeng/resources/primeng.css';


// FONTAWESOME: https://fontawesome.com/docs/web/use-with/scss#adding-font-awesome-to-your-compile
$fa-font-path: "./layouts/themes-scss/archive/fontawesome-free-6.5.1-web/webfonts";
@import 'themes-scss/archive/fontawesome-free-6.5.1-web/scss/fontawesome.scss';
@import 'themes-scss/archive/fontawesome-free-6.5.1-web/scss/solid.scss';
@import 'themes-scss/archive/fontawesome-free-6.5.1-web/scss/brands.scss';
@import 'themes-scss/archive/fontawesome-free-6.5.1-web/scss/regular.scss';
  
// FONTS
@import 'themes-scss/common/_common.scss';

// MY
@import 'themes-scss/primeng-components/_primeng-components.scss';

// FRAMES-LAYOUT
@import 'themes-scss/frames-layout/_frames-layouts.scss';

// THEMES
@import 'themes-scss/themes/fly_solutions/_fly_solutions_theme.scss';
@import 'themes-scss/themes/helisul/_helisul_theme.scss';