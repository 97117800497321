.cls-component {
    &-map-navigation {
        .map-container {
            margin: 0 !important;
            max-height: 550px !important;
        }

        .map-frame {
            height: 100%;
        }

        #map-navigation {
            height: 100%;
        }

        @media (min-width: 992px) { 
            .map-container {
                margin: 0 !important;
                max-height: 440px !important;
            }
        }
    }
}