.cls-component {
    &-map-speed-dial-action {
        position: relative;
        margin-top: -7rem;
        margin-left: 1rem;
        z-index: 1000;
        height: 500px;

        &-frame {
            position: absolute;
        }
    }
}