[data-layout_frame="login-basic-01"] {
    background-color: #374151;

    .cls {
        &-shared-login-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            height: 100vh;
            padding: 0;
            margin: 0;

            &-frame {
                display: flex;
                align-items: center;

                border: 4px solid #000;

                box-shadow: rgba(0255, 255, 255, 0.35) 0px 5px 15px;
            }

            &-info {
                height: 40rem;
                width: 40rem;
                overflow: hidden;
                border-right: 4px solid #000;

                img {
                    margin-left: -3rem;
                    width: 160%;
                    height: 100%;
                }
            }

            &-data {
                display: flex;
                flex-direction: column;
                justify-content: center;

                background-color: #FFF;

                padding: 1.5rem;
                height: 100%;
                width: 20rem;

                &-advice {
                    margin-bottom: 1.5rem;
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                &-extra {
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                    margin-top: -0.5rem;
                    margin-bottom: 0.5rem;
                }

                &-keep-connected {
                    label {
                        display: block;
                        font-size: 0.7rem;
                        margin-top: -1.15rem;
                        margin-left: 1.2rem;
                    }

                    .p-checkbox .p-checkbox-box {
                        width: 15px;
                        height: 15px;
                    }
                }

                &-link-forgot {
                    margin-top: 0.1rem;
                    margin-left: 0.5rem;

                    a {
                        font-size: 0.7rem;
                    }
                }

                &-buttons {
                    text-align: right;
                }
            }

            &-version {
                display: block;
                position: absolute;
                color: #000;
                text-align: right;
                width: 17rem;
                font-weight: bold;
                margin-top: 38rem;
            }

            &-dialog {
                .p-dialog {
                    background-color: #ffeaea;
                    border: 3px solid #000;
                }

                .p-dialog .p-dialog-header {
                    background-color: transparent;
                }

                .p-dialog .p-dialog-content {
                    background-color: transparent;
                }

                .p-dialog .p-dialog-footer {
                    background-color: transparent;
                }

                &-title {
                    color: #ae0c00;
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                &-message {
                    font-size: 1rem;
                    font-weight: 600;
                }

                &-button {
                    text-align: right;
                    width: 100%;
                }
            }
        }
    }
}