.cls-component {
    &-chip-risk-meteoro{

        .cls-ui-primeng-field-chips ul {
            width: 100%;
            height: 5.3rem;
            overflow-y: scroll;
            margin: 0 auto;
        }
    }
}