.cls-component {
    &-generate-pdf {
        .p-toolbar {
            padding: 0.5rem;
        }

        .p-datatable .p-datatable-thead>tr>th {
            padding: 0.6rem 1rem;
            color: #FFF;
            background: #0f4591;
            font-size: 0.8rem;
        }

        .p-datatable .p-datatable-tbody>tr>td {
            padding: 0.5rem 0.5rem;
        }

        &-check {
            margin-bottom: 0.4rem;
            .cls-ui-primeng-field-checkbox-label {
                display: block;
                margin-left: 1.5rem;
                margin-top: -1.15rem;
                font-size: 0.85rem;                  
            }           
            
            .p-checkbox .p-checkbox-box {
                width: 18px;
                height: 18px;
            }            
        }

        &-row {
            &-type {
                font-size: 0.7rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            &-name {
                font-size: 0.8rem;
            }

            &-frame {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0.4rem;
            }

            &-date {
                font-size: 0.8rem;
                font-weight: bold;
                color: #000;

                padding: 0.3rem;

                &-ok {
                    background-color: #7cfc00;
                    border: 1px solid #648c11;
                }

                &-not-ok {
                    background-color: #ff9999;
                    border: 1px solid #d73b3e;
                }
            }

            &-buttons {
                .p-component {
                    font-size: 0.7rem;
                    margin-left: 0.3rem;
                }

                .p-button.p-button-icon-only.p-button-rounded {
                    height: 1.5rem;
                }

                .p-button.p-button-icon-only {
                    width: 1.5rem;
                }
            }
        }
    }
}