.cls-component {
    &-user-tools {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-end;

        height: 3.45rem;
        width: 12.47rem;

       @media (max-width: 576px) {
            width: 6.3rem;

            .p-tieredmenu {
                // Houve um problema no posicionamento do Menu quando a tela é menor que 576, é preciso coloca ro posicionamento na mão mesmo...
                left: -6rem !important;
            }
        } 
        .p-button {
            background-color: rgba(255, 255, 255, 0.3);
            border: 1px solid rgba(255, 255, 255, 0.3);
        }

        .p-splitbutton {
            height: 3.4rem;
        }

        .p-splitbutton-defaultbutton {
            border-right: none;
            border-left: none;
            cursor: default;
        }

        .p-splitbutton-defaultbutton:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
        }

        &-avatar {
            margin-right: 0.5rem;

            @media (max-width: 576px) {
                margin-right: 0
            }

            .p-avatar {
                background-color: rgba(255, 255, 255, 0.2);
            }

            &-icon {
                border: 1px solid #FFFFFF;
            }

            &-img {
                border: none;
                display: block;
            }
        }

        &-ident {
            width: 5.5rem;

            @media (max-width: 576px) {
                display: none;
            }

            &-name {
                font-size: 0.9rem;
                font-weight: bold;
            }

            &-profile {
                font-size: 0.8rem;
            }
        }

        &-menu-item-user {
            border-bottom: 1px solid #dee2e6;
            margin: 0.25rem 0;
            padding: 0.5rem 1rem;

            &-name {
                font-weight: bold;
            }

            &-profile {
                font-weight: normal;
            }
        }
    }
}