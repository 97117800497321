.cls-component {
    &-logo-system{
        display: flex;
        background-color: #FF00FF;

        img {
            width: 5.7rem;
            height: 3.4rem;

            @media (max-width: 576px) {  
                width: 4rem;
                height: 2.4rem;
            }
        }       
    }
}