.cls {
    &-component-layout-table-flight-plan {
        .p-datatable-tbody {
            font-size: 1rem !important;
        }

        &-item {
            &-code {
                font-weight: bold;
            }

            &-aircraft-mark {
                font-weight: bold !important;
            }

            &-aerodrome {
                &-icao {
                    font-weight: bold !important;
                }

                &-coordinate {
                    font-size: 0.8rem;
                }

                &-name {
                    font-size: 0.8rem;
                }
            }

            &-date-zulu {
                font-weight: bold !important;
            }

            &-time-zulu {
                font-weight: bold !important;
            }

            &-button{
                .pi {
                    font-size: 0.8rem;
                }

                .p-button.p-button-icon-only {
                    width: 2rem;
                    padding: 0.5rem 0;
                }
                .p-button.p-button-icon-only.p-button-rounded {
                    border-radius: 50%;
                    height: 2rem;
                }
            }
        }
    }
}