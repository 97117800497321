.cls-component {
    &-message-frame {
        padding: 0;
        border-radius: 10px;
        margin-top: 1.2rem;
        margin-bottom: 0;

        .p-message {
            margin: 0.4rem 0;
            border-radius: 8px;
        }

        .p-message .p-message-wrapper {
            padding: 0.3rem 0.75rem;
        }

        .p-message-wrapper {
            display: flex;
            align-items: center;
        }

        .p-message .p-message-icon {
            font-size: 0.9rem;
            margin-right: 0.4rem;
        }

        .p-message .p-icon {
            margin-top: 0.2rem;
            width: 1.2rem;
            height: 1.2rem;
        }

        .p-message .p-message-close {
            width: 1.5rem;
            height: 1.5rem;
        }

        .p-message-close .p-icon {
            margin-top: -0.1rem;
            width: 1rem;
            height: 1rem;
        }

        .p-message-close.p-link {
            margin-left: auto;
            overflow: hidden;
            position: relative;
        }

        .cls-component-message-frame .p-message .p-message-wrapper {
            padding: 0.2rem 0.75rem;
        }
    }
}