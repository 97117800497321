.cls-component {
    &-map-search-location {        
        .map-container {
            margin: 0 !important;
            height: 500px !important;
        }

        .map-frame {
            height: 100%;
        }

        #map-search-location{
            height: 100%;
        }
    }
}