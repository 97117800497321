[data-layout_frame="horizontal-basic-01"] {
    .cls {
        &-hrt-frame {
            &-main {
                &-content{
                    background-color:#FFFFFF;
                    height: 100%;
                    margin-top:0.4rem;
                }                
            }
        }
    }

    .cls {
        &-hrt-frame {
            &-header {
                &-top {
                    background-color: $top_header-background-color;
                    width: 100%;
                    display: block;
                }

                &-menu {
                    background-color: $top_menu-background-color;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                }
            }
        }
    }
}