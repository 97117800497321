.cls-component {
    &-field-aerodrome-composed {
        width: 100%;
        margin-top: .6rem;
        margin-bottom: 0.5rem;

        .p-fieldset {
            padding: 0.2em 0.2em 1em;
        }

        .p-fieldset-content {
            padding: 0 0.5rem;
        }

        .p-fieldset-legend {
            background-color: transparent;
            border: none;
            font-size: 0.85rem;
            font-weight: 700;
            margin-left: 1.5rem;
        }

        .cls-field-wrong{
            position: relative;
            margin-top: 0.2rem;
            margin-bottom: -1.2rem;
        }

        &-button-frame{
            display: flex;
            align-content: center;
            margin-top: .6rem;
        }

        &-extra-frame {
            margin-top: -1.5rem;
        }

        &-aerodrome-legend {
            position: absolute;
            margin-top: -1.5rem;
            margin-left: -.5rem;

            img {
                height: 1.5rem;
                width: 1.5rem;
            }
        }

        &-name-label {
            position: relative;
            color: #a9a9a9;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: -0.7rem;
            overflow-y: hidden;
            overflow-x: hidden;
            height: 0.8rem;

            &-green{
                color: green !important;
            }
        }

        &-aerodrome-error {
            position: relative;
            color: #F00;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: -0.8rem;
            overflow-y: hidden;
            overflow-x: hidden;
            height: 0.8rem;
        }

        &-progress-bar {
            position: relative;
            margin-bottom: -0.8rem;
            overflow-y: hidden;
            overflow-x: hidden;
            padding: 0.19rem;
        }

        &-panel {
            display: flex;
            align-content: center;
            align-items: flex-start;
            margin-top: 0.1rem;        
        }

        &-button {
            margin-top: -0.5rem;
            margin-left: 0.2rem;

            .p-button {
                padding: 0.2rem 0;
                margin-top: 0.3rem;

                span {
                    font-size: 0.86rem;
                }
            }

            .p-button.p-button-icon-only {
                width: 1.8rem;
            }

            &-label {
                margin-top: 0.85rem;
            }

            &-close {
                position: relative;
                top: -0.3rem;
                right: 0.2rem;
                text-align: right;
                width: 100%;
                margin-top: 0.5rem;

                .p-button-icon {
                    margin-top: 0.2rem;
                }

                button {
                    font-size: .8rem;
                    height: 1.4rem;
                    width: 1.4rem;
                    padding: 0;
                }
            }
        }
    }
}