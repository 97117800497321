.cls-component{
    &-quick-message{
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        .p-toolbar {
            background: #374151;
            padding: 0.3rem 0.5rem;
        }

        .p-component {
            color: #FFF;
            font-weight: 600;
        }

        .p-button-icon{
            margin-top: 0.1rem;
        }

        &-button{
            font-size: 0.75rem;
            margin-left: 0.3rem;    
            padding: 0.2rem 0px;
            width: 1.5rem;
        }  

        &-panel{
            .p-panel.p-panel-toggleable .p-panel-header {
                font-size: 0.9rem;
                font-weight: bold;
                padding: 0 0.5rem;
            }

            .p-icon {
                width: 0.8rem;
                height: 0.8rem;
            }

            .p-panel .p-panel-footer {
                font-size: 0.7rem;
                font-weight: bolder;
                padding: 0.5rem 0.5rem;
                text-align: right;
            }
        }
        

        /*.p-accordion-header-link {
            padding: 0.4rem;
        }

        .p-icon {
            width: 0.7rem;
            height: 0.7rem;
        }

        &-label{
            font-size: 0.8rem;
        }

        &-item-label {
            font-size: 0.8rem;
            font-weight: 700;
        }
 
        .p-button.p-button-icon-only {
            font-size: 0.7rem;
            width: 2rem;
            margin-left: 0.5rem;
            
        }*/
    }
}
