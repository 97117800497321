.cls-component {
    &-panel-form-item{
        .p-panel .p-panel-header {
            background: #f9fafb !important;
            color: #4b5563 !important;
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
            font-weight: 600;
            padding: 0.5rem 0.8rem !important;
            letter-spacing: 0!important;
        }

        .p-accordion .p-accordion-header .p-accordion-header-link {
            padding: 0.5rem 0.8rem;
        }
         
        &-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            

            &-label{
                font-size: 1rem;
            }

            &-buttons{

            }
        }
    }
}