.cls-component{
    &-form {
        .field {
            margin-bottom: 0;
        }

        .p-dropdown.p-dropdown-clearable .p-dropdown-label {
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            font-size: 0.85rem;
            font-weight: 500;
        }
        
        .p-inputtext {
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
        }

        label {
            font-size: 0.70rem;
            margin-bottom: 0.3rem;
        }

        input{
            font-size: 0.85rem;
            font-weight: 500;
        }

        input::placeholder {
            font-weight: bold;
            font-size: 0.8rem;
            opacity: 0.4;
            color: #000080;
        }
          
    }
}