.cls {
    &-component-fieldset-formgrid {       
        .p-fieldset {
            margin-bottom: 0.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            padding: 0.35em 0.5em 0.625em;
        }

        .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
            padding: 0;
            border-radius: 3px;
            transition: box-shadow 0.2s;
        }    

        .p-fieldset .p-fieldset-content {
            padding: 0;        
        }
        
        .p-fieldset-legend{
            background-color: #FFF;
            border: none;
            padding: 0.3rem 0.2rem;
        }        
    }
}